<template>
  <div>
    <v-container class="py-10">
      <v-row>
        <v-col
          md="12"
          class="d-flex justify-end pl-5"
          v-if="returnAbility('test:store')"
        >
          <app-add-test
            :id="id"
            :idType="idType"
            @testSubmitted="fetchData(1)"
          ></app-add-test>
        </v-col>
        <v-col md="12">
          <app-base-table
            :tableOptions="tableOptions"
            @re-fetch-data="fetchData"
            @re-fetch-paginated-data="fetchData($event)"
            serverRoute="/test"
            :enableDelete="displayDeleteIcon"
          >
            <template slot="editFeature" slot-scope="{ row }">
              <app-add-test
                v-if="returnAbility('test:update')"
                :id="id"
                :idType="idType"
                :is-new="false"
                :item="row"
                @testSubmitted="fetchData(1)"
              ></app-add-test>
              <v-icon
                color="green"
                @click="$emit('displaySingleTest', row)"
                v-if="idType == 'edu-class' && returnAbility('mark:index')"
                >mdi-eye</v-icon
              >
             
              <v-icon
                color="blue"
                @click="sendNotification(row.id)"
                v-if="idType == 'edu-class' && returnAbility('test:notification')"
                >mdi-send</v-icon
              >
            </template>
          </app-base-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import addTest from "./addTest.vue";
import editTest from "./editTest.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  components: {
    appEditTest: editTest,
    appAddTest: addTest,
  },
  data() {
    return {};
  },
  props: {
    // either grade_id or edu-class_id
    id: {
      type: [String, Number],
    },
    // either grade or edu-class
    idType: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      tableOptions:"tests/getTableOptions"
    }),
  },
  methods: {
    ...mapActions({
      fetchTests: "tests/fetchTests",
    }),
    async sendNotification(id){
      let params = {
        id: id,
      };
      let res =  await axios.post("/test/notification", params);
          if(res.status==200)
          this.$Notifications(
            "تم إرسال الإشعارات بنجاح",
            { rtl: true, timeout: 2000 },
            "success"
          );
    },
    fetchData(page) {
      let params = {
        page,
      };
      if (this.idType == "grade") {
        params.grade_id = this.id;
      }
      if (this.idType == "edu-class") {
        params.edu_class_id = this.id;
      }
      this.fetchTests(params);
    },
  },
  created() {
    if (this.returnAbility("test:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style>
</style>
